import { storeDevice } from "@store/storeDevice";
import { useEffect } from "react";
import { initializeApp, getApps } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Plugins } from "@capacitor/core";
import alertToast from "@core/AlertToast";
import { Api } from "@api/ApiTransport";
import { useAuthStore } from "@store/storeAuth";

const { PushNotifications } = Plugins;
const api = Api.getInstance();

const firebaseConfig = {
  apiKey: "AIzaSyBkZzwIUbzI3g77ApSZvJxbysVvEImUWt8",
  authDomain: "mrbolletta-zumbat-v3.firebaseapp.com",
  projectId: "mrbolletta-zumbat-v3",
  storageBucket: "mrbolletta-zumbat-v3.appspot.com",
  messagingSenderId: "157788660783",
  appId: "1:157788660783:web:4f4ce0c57863a4922f12da",
};

const useFirebaseNotifications = () => {
  // const [isFirebaseInitialized, setIsFirebaseInitialized] = useState(false);
  const {
    device: { platform },
  } = storeDevice();
  const { justSigned } = useAuthStore();
  //   console.log(platform, "platform");

  useEffect(() => {
    if (platform && justSigned) {
      // Initialize Firebase
      // Initialize Firebase only if it hasn't been initialized in the service worker
      if (!getApps().length) {
        console.log("Firebase initialized");
        initializeApp(firebaseConfig);
      }
      //   const messaging = getMessaging();
      // setIsFirebaseInitialized(true);

      // Check if we're running on a native platform (iOS/Android)
      if (platform === "ios" || platform === "android") {
        // Register for push notifications for native apps (iOS/Android)
        PushNotifications.register();

        // Handle push registration success or failure
        PushNotifications.addListener("registration", (token: any) => {
          console.log("Push registration success, token: " + token.value);

          // SEND TOKEN TO BACKEND HERE
          try {
            api.post("/admin/saveFirebaseToken", {
              fcmToken: token.value,
              deviceType: platform,
            });
          } catch (error) {
            console.error("Failed to get Firebase token for web", error);
          }
        });

        PushNotifications.addListener("registrationError", (error: any) => {
          console.error("Push registration failed", error);
        });

        // Handle push notifications when the app is in the foreground
        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification: any) => {
            console.log("Push received: ", notification);
            // Display the notification using your alertToast function
            alertToast("success", notification.title, notification.body);
          }
        );

        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification: any) => {
            console.log("Notification action performed", notification);
          }
        );
      } else {
        console.log("Web platform");
        // Register the Service Worker and get FCM token for web push notifications
        if ("serviceWorker" in navigator) {
          const messaging = getMessaging();

          navigator.serviceWorker
            .register("/firebase-messaging-sw.js")
            .then((registration) => {
              console.log(
                "Service Worker registered with scope:",
                registration.scope
              );
              // Once the service worker is registered, get the token
              getFirebaseToken(registration);
            })
            .catch((error) => {
              console.error("Service Worker registration failed:", error);
            });
          // }

          const getFirebaseToken = async (
            registration: ServiceWorkerRegistration
          ) => {
            try {
              const token = await getToken(messaging, {
                vapidKey:
                  "BOgPkbCrCN12IiNMNiple8iwSIfucSo4WF2e-KclP0xr3EmjRmk6PX9ApclWxOgJ7yHCAW2pHPabISu8_g8mG80", // VAPID key
                serviceWorkerRegistration: registration, // Use the registered service worker
              });

              // SEND TOKEN TO BACKEND HERE
              await api.post("/admin/saveFirebaseToken", {
                fcmToken: token,
                deviceType: platform,
              });
              console.log("Firebase token for web:", token);
            } catch (error) {
              console.error("Failed to get Firebase token for web", error);
            }
          };

          // Listen for foreground messages - so when app is open
          onMessage(messaging, (payload) => {
            console.log("Message received on web: ", payload);
            if (payload?.notification?.title) {
              alertToast(
                "success",
                payload.notification.title,
                payload.notification.body
              );
            }
          });
        }
      }
    }
  }, [platform, justSigned]);
};

export default useFirebaseNotifications;
