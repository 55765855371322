import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useStatusRedirect from "./useStatusRedirect";
import { useAuthStore } from "@store/storeAuth";
import useApiHoc from "./useApiHoc";
import { useSessionStore } from "@store/storeSession";
import { useStoreError } from "@store/storeError";
import { useStoreLoader } from "@store/storeLoader";
import STATUS_MAPPING from "@constants/STATUS_MAPPING";
import { jwtDecode } from "jwt-decode";
import useGoToApp from "./useGoToApp";
import { Api } from "@api/ApiTransport";
import { set } from "cypress/types/lodash";
import ROUTES from "@routes/constants";
const api = Api.getInstance();

function useAuth() {
  const [isLoading, setIsLoading] = useState(false);
  const [redirectingAfterSaveParams, setRedirectingAfterSaveParams] =
    useState(false);
  const { fetchUser, user, userLogOut, setJustSigned } = useAuthStore();
  const {
    session,
    getSession,
    getSessionById,
    saveIdAndToken,
    getSavedIdAndToken,
    paramToken,
  } = useSessionStore();
  const { setError } = useStoreError();
  const { stopLoader, loader, setLoaderBg } = useStoreLoader();
  const apiHoc = useApiHoc();
  const statusRedirect = useStatusRedirect();
  const isOnboarding = localStorage.getItem("isOnboarding");
  const isSessionComplete = localStorage.getItem("isSessionComplete");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionIdFromParam = searchParams.get("idSession");
  const sessionTokenFromParam = searchParams.get("token");
  const throughAdminTokenParam = searchParams.get("throughAdminToken");
  const goToApp = useGoToApp();
  // console.log(paramToken, "paramToken");
  // console.log(user, "USER INSIDE USE AUTH");

  const decodeToken = (token: string) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      return null;
    }
  };

  const isTokenExpired = (decodedToken: any) => {
    return decodedToken?.exp && Date.now() >= decodedToken.exp * 1000;
  };

  // Check if tokens are expired
  useEffect(() => {
    const token =
      localStorage.getItem("throughAdminToken") ||
      localStorage.getItem("token");
    if (token) {
      const decodedToken = decodeToken(token);
      const isTokenExpiredNow = isTokenExpired(decodedToken);

      if (isTokenExpiredNow) {
        userLogOut();
        setError("Sessione scaduta, effettua nuovamente il login");
        return;
      }
    }

    if (paramToken && token) {
      const decodedToken = decodeToken(token);
      const decodedParamToken = decodeToken(paramToken);

      if (decodedParamToken && decodedToken) {
        const isParamTokenExpired = isTokenExpired(decodedParamToken);
        const isTokenExpiredNow = isTokenExpired(decodedToken);

        if (isParamTokenExpired) {
          if (isTokenExpiredNow) {
            userLogOut();
            setError("Sessione scaduta, effettua nuovamente il login");
          } else {
            goToApp();
          }
        }
      }
    }
  }, [paramToken, location, session]);

  // Fetch user
  useEffect(() => {
    // Check if logged in through admin
    const throughAdminTokenStorage = localStorage.getItem("throughAdminToken");
    const normalTokenStorage = localStorage.getItem("token");

    if (throughAdminTokenParam) {
      if (
        !throughAdminTokenStorage ||
        throughAdminTokenStorage !== throughAdminTokenParam
      ) {
        localStorage.clear();
        api.setAuthorizationHeader(throughAdminTokenParam);
        localStorage.setItem("throughAdminToken", throughAdminTokenParam);
      }
    }

    const token =
      throughAdminTokenStorage || throughAdminTokenParam || normalTokenStorage;
    if (token) {
      const decodedToken = decodeToken(token);
      if (!isTokenExpired(decodedToken)) {
        setIsLoading(true);
        if (user == null) {
          fetchUser(
            async () => {
              setIsLoading(false);
              setJustSigned(true);
            },
            (error) => {
              setIsLoading(false);
              setError(error);
            }
          );
        } else {
          setIsLoading(false);
        }
      } else {
        userLogOut();
        setError("Sessione scaduta, effettua nuovamente il login");
      }
    } else {
      userLogOut();
    }
  }, [user]);

  // Get session
  useEffect(() => {
    const fetchSavedIdAndToken = async () => {
      if (sessionIdFromParam && sessionTokenFromParam) {
        await apiHoc(() =>
          saveIdAndToken(
            { idSession: sessionIdFromParam, token: sessionTokenFromParam },
            (id) => {
              fetchUser();
              setJustSigned(true);
              getSessionById(id);
            }
          )
        );
      } else {
        if (!redirectingAfterSaveParams) {
          const id = localStorage.getItem("idToFetchSavedSessionTokenAndId");
          if (id) {
            await apiHoc(() =>
              getSavedIdAndToken(id, (id) => {
                if (id) {
                  getSessionById(id);
                } else {
                  console.log("GET ALL USER SESSION IF NO PARAMS");
                  getSession();
                }
              })
            );
          } else {
            apiHoc(getSession);
          }
        }
        if (redirectingAfterSaveParams) setRedirectingAfterSaveParams(false);
      }
    };
    isOnboarding && fetchSavedIdAndToken();
  }, [sessionIdFromParam, sessionTokenFromParam]);

  // Redirect
  useEffect(() => {
    const redirectUser = async () => {
      console.log(session, "SESSION INSIDE REDIRECT USER");
      if (session && Object.keys(session).length) {
        await statusRedirect(session.status);
        setRedirectingAfterSaveParams(true);

        if (loader > 0) {
          setTimeout(() => {
            stopLoader();
            setLoaderBg(false);
          }, 200);
        }
      }
    };

    if (
      location.pathname ==
        STATUS_MAPPING[session?.status as keyof typeof STATUS_MAPPING] &&
      loader > 0
    ) {
      stopLoader();
      setLoaderBg(false);
    }

    if (
      isOnboarding &&
      isSessionComplete == null &&
      location.pathname !==
        STATUS_MAPPING[session?.status as keyof typeof STATUS_MAPPING]
    ) {
      redirectUser();
    }
  }, [session, isOnboarding]);

  return { isLoading };
}

export default useAuth;

// fetchUser(async () => {
//   console.log(paramToken, "PARAM TOKEN INSIDE FETCH USER CALL")
//   if (paramSessionId && paramToken) {
//     console.log("GET PARAMS SESSION");
//     await apiHoc(() => getSessionById(paramSessionId), () => {
//       setIsLoading(false)
//     });
//   } else {
//     console.log("GET ALL USER SESSION IF NO PARAMS");
//     await apiHoc(getSession, () => {
//       setIsLoading(false)
//     });
//   }
//   setIsLoading(false)
// }, (error) => {
//   setIsLoading(false)
//   setError(error);
// });

// FETCH SESSION BY ID IF PARAMS ARE PRESENT OR SESSION
// useEffect(() => {
//   if (session?.id !== Number(paramSessionId)) {
//     if (paramSessionId && paramToken) {
//       apiHoc(() => getSessionById(paramSessionId));
//     } else {
//       console.log("GET ALL USER SESSION IF NO PARAMS");
//       apiHoc(getSession);
//     }
//   }
// }, [fetchSessionAfterUserRedirectingAfterSaveParams, paramSessionId]);
